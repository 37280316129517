<script setup lang="ts">
import { TrackingEvents } from '@/enums';
import { useDev } from '@/utils/composables/useDev';
import { useUserStore } from '@/stores/user';
import {
	PrimeButton,
	PrimeToggle,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { InAppBrowser } from '@capgo/inappbrowser';
import { maidStorage, showAlerts } from '@/utils/local-storage';
import { useAppStore } from '@/stores/app';
import { storeToRefs } from 'pinia';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { UPDATE_AVAILABLE_MODAL } from '@/constants/modals';
import { App as CapacitorApp } from '@capacitor/app';
import { onMounted, ref } from 'vue';

const { appMeta } = useDev();
const userStore = useUserStore();
const { isMobileApp, platform } = useMobileApp();
const appStore = useAppStore();
const { appLogMessages, isNativeAppTrackingEnabled, getAdvertisingStatus } =
	storeToRefs(appStore);
const modalStorage = useModalStorage();

const generateNativeError = async () => {
	try {
		await (window as any).testCrash();
	} catch (e) {
		console.log('Native error catch::', e);
	}
};

const generateTrackingEvent = async () => {
	await appStore.trackEvent(TrackingEvents.SURVEY_COMPLETED, {
		survey_id: 'test_survey_id',
		survey_points: '100',
		survey_count: '1',
	});
	console.log('Tracking event sent');
};
const generateTestError = () => {
	const env = `env::${import.meta.env.MODE} | platform::${platform.value}`;
	const testObj = {} as any;
	try {
		testObj.items.map((i: unknown) => console.log(i));
		//@ts-ignore
		throw new Error(env, '//Test error in try block //');
	} catch (e) {
		console.log(env, '//ERROR FROM CATCH::', e);
	}
	throw new Error(`${env} //Test error //`);
};

const openInAppBrowser = async () => {
	await InAppBrowser.openWebView({
		url: 'https://freetoolonline.com/camera-test.html',
		title: 'Test camera',
		closeModal: true,
		showReloadButton: true,
		closeModalTitle: 'Close',
		closeModalDescription: 'Are you sure?',
		closeModalOk: 'Close',
		closeModalCancel: 'Stay',
	});
};

const openInAppBrowserForceDark = async () => {
	const script =
		"await import('https://unpkg.com/darkreader@4.9.89/darkreader.js');\n" +
		'DarkReader.enable({brightness: 100,contrast: 100,sepia: 0,});';
	InAppBrowser.openWebView({
		url: 'https://www.onlinemictest.com',
		isPresentAfterPageLoad: true,
		preShowScript: script,
	});
};

const openInAppBrowserMicrophone = async () => {
	await InAppBrowser.openWebView({
		url: 'https://www.onlinemictest.com',
		title: 'Test microphone',
		closeModal: true,
		showReloadButton: true,
		closeModalTitle: 'Close',
		closeModalDescription: 'Are you sure?',
		closeModalOk: 'Close',
		closeModalCancel: 'Stay',
	});
};

const openInAppBrowserMicSurvey = async () => {
	await InAppBrowser.openWebView({
		url: 'https://conjointly.online/study/558701/1qekuzx29tjx17iutc49',
		title: 'Test microphone',
		closeModal: true,
		showReloadButton: true,
		closeModalTitle: 'Close',
		closeModalDescription: 'Are you sure?',
		closeModalOk: 'Close',
		closeModalCancel: 'Stay',
	});
};

const appVersionInfo = ref(appMeta.value.version);

onMounted(async () => {
	if (isMobileApp) {
		const appInfo = await CapacitorApp.getInfo();
		appVersionInfo.value = `${appInfo.version} (${appInfo.build})`;
	}
});

const handleUpdateApp = () => {
	appStore.setNativeUpdate(true);
	modalStorage.pushModal({
		name: UPDATE_AVAILABLE_MODAL,
	});
};
</script>

<template>
	<div>
		<h4>App info</h4>
		<div>Email: {{ userStore.email }}</div>
		<div>API: {{ appMeta.api }}</div>
		<div>VERSION: {{ appVersionInfo }}</div>
		<div>PLATFORM: {{ platform }}</div>
		<div>IS NATIVE: {{ isMobileApp }}</div>
		<template v-if="isMobileApp">
			<div>STATUS: {{ getAdvertisingStatus }}</div>
			<div>IS MOBILE TRACKING ENABLED: {{ isNativeAppTrackingEnabled }}</div>
			<div>MAID: {{ maidStorage }}</div>
		</template>
		<PrimeToggle
			v-if="isMobileApp"
			v-model="showAlerts"
			:checked="showAlerts"
			label="Show alerts when tracking events"
		/>
		<div>
			App Logs:
			<p v-for="message in appLogMessages" :key="message">{{ message }}</p>
		</div>
		<PrimeButton
			full-width
			variant="grey"
			type="text"
			label="Throw error"
			@click="generateTestError"
		/>
		<PrimeButton
			full-width
			variant="grey"
			type="text"
			label="Test analytics event"
			@click="generateTrackingEvent"
		/>
		<br />
		<div v-if="isMobileApp">
			<PrimeButton
				full-width
				variant="grey"
				type="text"
				label="Update the app"
				@click="handleUpdateApp"
			/>
			<PrimeButton
				full-width
				variant="grey"
				type="text"
				label="Throw native crash"
				@click="generateNativeError"
			/>
			<PrimeButton
				full-width
				variant="grey"
				type="text"
				label="Open web browser Camera test"
				@click="openInAppBrowser"
			/>
			<PrimeButton
				full-width
				variant="grey"
				type="text"
				label="Open web browser Camera test with dark reader"
				@click="openInAppBrowserForceDark"
			/>
			<PrimeButton
				full-width
				variant="grey"
				type="text"
				label="Open web browser microphone test"
				@click="openInAppBrowserMicrophone"
			/>
			<PrimeButton
				full-width
				variant="grey"
				type="text"
				label="Open web browser microphone test with survey"
				@click="openInAppBrowserMicSurvey"
			/>
		</div>
	</div>
</template>
