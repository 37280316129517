import { onMounted, ref } from 'vue';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import type { User } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import type { FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { useAuthStore } from '@/stores/auth';
import { SocialProviders } from '@/enums';
import API from '@/api';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import type { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { deviceStorage } from '@/utils/local-storage';
import { Capacitor } from '@capacitor/core';

export const useSocialAuth = () => {
	onMounted(async () => {
		// use hook after platform dom ready
		GoogleAuth.initialize({
			...(Capacitor.isNativePlatform()
				? {}
				: { clientId: import.meta.env.VITE_GID }),
			scopes: ['email'],
			grantOfflineAccess: false,
		});

		await FacebookLogin.initialize({ appId: import.meta.env.VITE_FID });
	});

	const authToken = ref('');

	const authStore = useAuthStore();

	const socialAuth = async (provider: string) => {
		try {
			await GoogleAuth.signOut();
			await FacebookLogin.logout();
		} catch (e) {
			console.error('Social auth logout error:::', e);
		}

		try {
			let result = null;

			if (provider === SocialProviders.GOOGLE) {
				result = (await GoogleAuth.signIn()) as User;
				authToken.value = result.authentication.accessToken;
			} else if (provider === SocialProviders.FACEBOOK) {
				result = (await FacebookLogin.login({
					permissions: ['email'],
				})) as FacebookLoginResponse;
				authToken.value = result.accessToken?.token || '';
			} else if (provider === SocialProviders.APPLE) {
				const { response }: SignInWithAppleResponse =
					await SignInWithApple.authorize({
						clientId: import.meta.env.VITE_APPLE_ID,
						redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
						scopes: 'email',
						state: '12345',
						nonce: 'nonce',
					});
				authToken.value = response.identityToken;
			}

			if (authToken.value) {
				await authStore.checkSocial({
					token: authToken.value,
					provider,
				});
			} else {
				await API.post('errors/js', {
					message: `No access token :::: ${provider} :::: Auth Result :::: ${JSON.stringify(
						result
					)} :::: Device :::: ${deviceStorage.value}`,
				});
			}
		} catch (e) {
			await API.post('errors/js', {
				message: `Error on social auth ::: ${provider} :::: Error message :::: ${JSON.stringify(
					// @ts-ignore
					e.message
				)} :::: Error :::: ${e} :::: Device :::: ${deviceStorage.value}`,
			});
		}
	};

	return {
		socialAuth,
	};
};
