<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRewardsStore } from '@/stores/rewards';
import { useUserStore } from '@/stores/user';
import { Platforms, type RewardCategories } from '@/enums';
import {
	NO_REWARDS_MESSAGE,
	NO_REWARDS_TITLE,
	NO_REWARDS_WITH_NAME,
	SEARCH_REWARDS,
	SELECT,
	SELECT_REWARDS,
} from '@/constants/locales';
import type { RewardsItem } from '@/types';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import RewardsCategory from '@/views/surveys/components/user-dashboard/rewards/RewardsCategory.vue';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	PrimeText,
	PrimeButton,
	PrimeInput,
	PrimeSkeleton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiSearch } from '@primeinsightsgroupllc/prime-icons';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { useRoute, useRouter } from 'vue-router';
import { useMobileApp } from '@/utils/composables/useMobileApp';

const { open } = defineProps<{
	open: boolean;
	options?: unknown;
}>();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const { platform } = useMobileApp();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const rewardsStore = useRewardsStore();
const userStore = useUserStore();
const searchValue = ref('');
const selectedRewardOptionId = ref(0);

const handleCloseModal = () => {
	emit('close-modal');
};

onMounted(async () => {
	selectedRewardOptionId.value = 0;
	if (open) {
		await rewardsStore.fetchRewards();
	}
});

const filteredRewards = computed<Record<
	RewardCategories,
	RewardsItem[]
> | null>(() => {
	if (rewardsStore.rewards) {
		const filteredItems = {} as Record<string, RewardsItem[]>;
		for (const [key, value] of Object.entries(rewardsStore.rewards)) {
			filteredItems[key] = value.filter((item) =>
				item.name.toLowerCase().includes(searchValue.value.toLowerCase())
			);
		}
		return filteredItems;
	} else {
		return rewardsStore.rewards;
	}
});

const isEmptyFilterRewards = computed(() =>
	filteredRewards.value
		? !Object.values(filteredRewards.value).filter((item) => item.length).length
		: false
);

const emptySearchResult = computed(() => {
	return searchValue.value.length
		? t(NO_REWARDS_WITH_NAME, { value: searchValue.value })
		: t(NO_REWARDS_MESSAGE);
});

const postSelectedReward = async () => {
	if (selectedRewardOptionId.value) {
		const responseStatus = await rewardsStore.selectReward(
			selectedRewardOptionId.value
		);

		if (responseStatus === 422) {
			notify({
				body: rewardsStore.error?.message || '',
			});
			return;
		}
		await userStore.fetchUserData();
		selectedRewardOptionId.value = 0;
		handleCloseModal();
		if (route.name !== 'claim-reward') {
			await router.push({ name: 'claim-reward' });
		}
	}
};

const setSelectedOption = (event: number) => {
	selectedRewardOptionId.value = event;
};

const footerPaddingStyle = computed(() => {
	return platform.value === Platforms.IOS
		? '1.125rem 1.125rem calc(env(safe-area-inset-bottom))'
		: '1.125rem';
});
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(SELECT_REWARDS) }}</template>
	</ModalCommonContent>
	<div class="rewards-modal-container">
		<PrimeInput
			v-model="searchValue"
			:start-icon="PiSearch"
			:placeholder="$t(SEARCH_REWARDS)"
			:float-placeholder="true"
			class="reward-search-input"
		/>

		<div v-if="rewardsStore.initLoading" class="rewards-list">
			<PrimeSkeleton v-for="i in 7" :key="i" class="reward-skeleton" />
		</div>

		<div v-else-if="!isEmptyFilterRewards" class="rewards-list">
			<template
				v-for="(categoryItems, categoryName) in filteredRewards"
				:key="categoryName"
			>
				<RewardsCategory
					v-if="categoryItems.length"
					:category="categoryName"
					:items="categoryItems"
					:selected-option="selectedRewardOptionId"
					@select-option="setSelectedOption"
				/>
			</template>
		</div>

		<div v-else class="fallback-wrapper">
			<PrimeText tag="p" weight="500" size="3xl" class="fallback-title">
				{{ $t(NO_REWARDS_TITLE) }}
			</PrimeText>
			<PrimeText tag="p" color="grey-600" size="sm" class="fallback-message">
				{{ isEmptyFilterRewards ? emptySearchResult : $t(NO_REWARDS_MESSAGE) }}
			</PrimeText>
		</div>
		<div v-if="!isEmptyFilterRewards" class="modal-footer">
			<PrimeButton
				variant="secondary"
				full-width
				:label="$t(SELECT)"
				class="select-button"
				:disabled="!selectedRewardOptionId"
				@click="postSelectedReward"
			/>
		</div>

		<PrimeLoader v-if="rewardsStore.loading" />
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.rewards-modal-container {
	height: 80vh;
	height: 80svh;
}

.reward-skeleton {
	height: 3.6rem;
	margin: 0.75rem 1.5rem;
}

.rewards-list {
	padding-bottom: 5rem;
	height: calc(100% - 5rem);
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0;
	}
}

.modal-footer {
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	border-top: 1px solid var(--p-grey-400);
	border-radius: 0 0 var(--5s-radius-xl) var(--5s-radius-xl);
	background-color: rgba(255, 255, 255, 0.9);
	backdrop-filter: blur(1.5px);
	-webkit-backdrop-filter: blur(1.5px);
	padding: v-bind(footerPaddingStyle);

	.select-button {
		max-width: 21.875rem;
	}
}

.fallback-wrapper {
	height: calc(100% - 5rem);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 5rem;

	& .fallback-title {
		margin-bottom: 0.125rem;
	}
}

:deep(.reward-search-input) {
	margin-bottom: 0.625rem;
}
</style>
