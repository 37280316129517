import { useModalStorage } from '@/utils/composables/useModalStorage';
import { onBeforeMount, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/auth';
import { useAppStore } from '@/stores/app';
import {
	FIRST_SURVEY_COMPLETED_MODAL,
	NEW_PASSWORD_SENT,
	SET_USER_PASSWORD_MODAL,
} from '@/constants/modals';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { useRoute } from 'vue-router';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useMessagesStore } from '@/stores/messages';
import { pendingAxiosRequests } from '@/api/api-service';

export const useAppInit = () => {
	const route = useRoute();
	const modalStorage = useModalStorage();
	const { isMobileApp } = useMobileApp();
	const userStore = useUserStore();
	const authStore = useAuthStore();
	const messageStore = useMessagesStore();
	const { isPasswordSet, userData } = storeToRefs(useUserStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const { authToken } = useAppCookies();
	const appStore = useAppStore();
	const { getTrackingMaid } = storeToRefs(appStore);

	onBeforeMount(async () => {
		// Set user token if it is in cookies
		if (!userAuthToken.value && authToken) {
			authStore.setToken(authToken);
		}
		// Close new password sent modal if it is opened
		if (modalStorage.activeModal.value.name === NEW_PASSWORD_SENT) {
			await modalStorage.shiftModal();
		}

		await appStore.fetchAppMeta();
	});

	onMounted(() => {
		// Close active modal if page reload or tab close
		if (!isMobileApp) {
			window.addEventListener('beforeunload', modalStorage.closeAllModals);
		}
	});

	const init = async () => {
		pendingAxiosRequests.cancelAll();
		try {
			userStore.setInitialDataLoading(true);
			await Promise.all([userStore.fetchUserData(), userStore.fetchUserMeta()]);
		} finally {
			userStore.setInitialDataLoading(false);
		}

		await Promise.allSettled([
			messageStore.fetchUserNotifications(),
			messageStore.fetchUserAnnouncements(),
		]);

		if (getTrackingMaid.value) {
			await appStore.storeMaid();
		}
	};

	// Fetch user data based on token
	watch(
		userAuthToken,
		async (token) => {
			if (token) {
				await init();

				//If user does not have password - show modal to set it
				if (userData.value && !isPasswordSet.value) {
					await modalStorage.pushModal({
						name: SET_USER_PASSWORD_MODAL,
						options: { useDefaultClose: false },
					});
				}
			}
		},
		{ immediate: true }
	);

	// Close all modals if route change
	watch(
		() => route.name,
		(newRoute, prevRoute) => {
			if (
				newRoute !== prevRoute &&
				modalStorage.activeModal.value.name !== FIRST_SURVEY_COMPLETED_MODAL
			) {
				modalStorage.shiftModal();
			}
		}
	);
};
