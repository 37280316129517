import API from '@/api';
import type {
	AuthPayload,
	ChangePasswordForm,
	CheckEmailResponse,
	LoginResponse,
	MessageResponse,
	ResetPasswordPayload,
	SocialAuthCallback,
	SocialCallbackResponse,
	StatusResponse,
	UserLoginResponse,
	RegisterPayload,
	UserLocale,
} from '@/types';
import type { AxiosResponse } from 'axios';

export const authRegister = async (
	payload: RegisterPayload
): Promise<StatusResponse> => {
	const { status } = await API.post('/auth/register', payload);
	return { status };
};

export const authResendVerificationEmail = async (
	email: string
): Promise<AxiosResponse> => {
	return await API.post('/auth/resend-verification-email', { email });
};

export const authLogin = async (
	payload: AuthPayload
): Promise<LoginResponse> => {
	const { data } = await API.post<LoginResponse>('/auth/login', payload);
	return data;
};

export const authVerifyCode = async (
	code: string,
	email: string
): Promise<LoginResponse> => {
	const { data } = await API.post<LoginResponse>('/auth/verify-email-code', {
		email,
		code,
	});
	return data;
};

export const authResetPassword = async (
	payload: ResetPasswordPayload
): Promise<LoginResponse & StatusResponse> => {
	const { data, status } = await API.post<LoginResponse>(
		'/auth/reset-password',
		payload
	);
	return {
		...data,
		status,
	};
};

export const authForgotPassword = async (
	email: string
): Promise<AxiosResponse> => {
	return await API.post('/auth/forgot-password', { email });
};

export const authLoginWithUrl = async <T>(
	loginUrl: string
): Promise<{ data: T } & StatusResponse> => {
	const { data, status } = await API.get<T>(loginUrl);
	return {
		data,
		status,
	};
};

export const authSendOneTimeToken = async (
	email: string
): Promise<AxiosResponse> => {
	return await API.post('/auth/send-one-time-login-link', { email });
};

export const authSocialCallback = async (
	params: SocialAuthCallback
): Promise<{
	data: SocialCallbackResponse | LoginResponse;
	status: number;
}> => {
	const { data, status } = params.callbackUrl
		? await API.get<SocialCallbackResponse>(`auth/social/callback`, {
				params: params.callbackUrl,
			})
		: await API.post<LoginResponse>('auth/social/token', params);

	return { data, status };
};

export const authSocialLoginToExisting = async (
	signedUrl: string,
	authData: SocialCallbackResponse,
	password: string
): Promise<{ data: LoginResponse; status: number }> => {
	const { data, status } = await API.post<LoginResponse>(signedUrl, {
		...authData,
		password,
	});

	return { data, status };
};

export const authSocialRegisterWithSignature = async (
	signedUrl: string,
	authData: SocialCallbackResponse & UserLocale
): Promise<{ data: UserLoginResponse; status: number }> => {
	const { data, status } = await API.post<UserLoginResponse>(signedUrl, {
		...authData,
	});

	return {
		data,
		status,
	};
};

export const authSocialRegister = async (
	data: SocialCallbackResponse & UserLocale,
	email: string
): Promise<StatusResponse> => {
	const { status } = await API.post('/auth/register', {
		...data,
		email,
	});

	return { status };
};

export const authCheckEmail = async (
	email: string
): Promise<{ data: CheckEmailResponse }> => {
	const { data } = await API.post<CheckEmailResponse>('/auth/check', {
		email,
	});

	return { data };
};

export const authSetPassword = async (
	password: string
): Promise<MessageResponse & StatusResponse> => {
	const { status, data } = await API.put<MessageResponse>(
		'/auth/set-password',
		{ password }
	);

	return {
		...data,
		status,
	};
};

export const authChangeEmail = async ({
	email,
	password,
}: AuthPayload): Promise<StatusResponse> => {
	const { status } = await API.post<MessageResponse>('/auth/change-email', {
		to_email: email,
		password: password,
	});

	return { status };
};

export const authChangePassword = async (
	payload: ChangePasswordForm
): Promise<MessageResponse & StatusResponse> => {
	const { data, status } = await API.put<MessageResponse>(
		'/auth/change-password',
		payload
	);

	return {
		...data,
		status,
	};
};

export const authLogout = async () => {
	await API.post('/api/logout');
};
